<template>
  <TransitionRoot as="template" :show="newGuidePanelOpen">
    <Dialog class="relative z-40">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <form
                  class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                >
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle
                            class="text-base font-semibold text-gray-900"
                            >New Guide</DialogTitle
                          >
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to
                            create your new guide.
                          </p>
                        </div>
                        <div class="flex h-7 items-center">
                          <button
                            type="button"
                            class="relative text-gray-400 hover:text-gray-500"
                            @click="newGuidePanelOpen = false"
                          >
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <SvgIcon name="x-mark" class="size-6" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div
                      class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"
                    >
                      <!-- Guide geoentity -->
                      <Combobox
                        v-model="selectedAddressTree"
                        as="div"
                        @update:model-value="query = ''"
                      >
                        <div
                          class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                        >
                          <div>
                            <ComboboxLabel
                              class="block text-sm/6 font-medium text-gray-900 sm:mt-1.5"
                            >
                              Location
                            </ComboboxLabel>
                          </div>
                          <div class="sm:col-span-2">
                            <div class="relative">
                              <ComboboxInput
                                class="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                                :display-value="(address) => address?.name"
                                @change="query = $event.target.value"
                                @blur="query = ''"
                              />
                              <ComboboxButton
                                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                              >
                                <SvgIcon
                                  name="chevron-down"
                                  class="size-5 text-gray-400"
                                />
                              </ComboboxButton>

                              <ComboboxOptions
                                v-if="filteredAddressesTrees.length > 0"
                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                              >
                                <ComboboxOption
                                  v-for="address in filteredAddressesTrees"
                                  :key="address.id"
                                  v-slot="{ active, selected }"
                                  :value="address"
                                  as="template"
                                >
                                  <li
                                    :class="[
                                      'relative cursor-default select-none py-2 pl-3 pr-9',
                                      active
                                        ? 'bg-indigo-600 text-white outline-none'
                                        : 'text-gray-900',
                                    ]"
                                  >
                                    <div class="flex">
                                      <span
                                        :class="[
                                          'truncate',
                                          selected && 'font-semibold',
                                        ]"
                                      >
                                        {{ address.name }}
                                      </span>
                                      <span
                                        :class="[
                                          'ml-2 truncate text-gray-500',
                                          active
                                            ? 'text-indigo-200'
                                            : 'text-gray-500',
                                        ]"
                                      >
                                        {{ address.parentChain }}
                                      </span>
                                    </div>

                                    <span
                                      v-if="selected"
                                      :class="[
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                      ]"
                                    >
                                      <SvgIcon name="check" class="size-5" />
                                    </span>
                                  </li>
                                </ComboboxOption>
                              </ComboboxOptions>
                            </div>
                            <p class="mt-3 text-sm/6 text-gray-400">
                              Only locations with no guides will be shown.
                            </p>
                          </div>
                        </div></Combobox
                      >
                      <!-- Project name -->
                      <div
                        class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                      >
                        <div>
                          <label
                            for="guide-name"
                            class="block text-sm/6 font-medium text-gray-900 sm:mt-1.5"
                            >Guide name</label
                          >
                        </div>
                        <div class="sm:col-span-2">
                          <input
                            id="guide-name"
                            type="text"
                            name="guide-name"
                            class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          />
                        </div>
                      </div>

                      <!-- Project description -->
                      <div
                        class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                      >
                        <div>
                          <label
                            for="project-description"
                            class="block text-sm/6 font-medium text-gray-900 sm:mt-1.5"
                            >Description</label
                          >
                        </div>
                        <div class="sm:col-span-2">
                          <textarea
                            rows="3"
                            name="project-description"
                            id="project-description"
                            class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          />
                        </div>
                      </div>
                      <div class="px-4 sm:px-6 text-sm text-gray-500">
                        <pre>{{ addressesTreesWithParentsAndNoGuides }}</pre>
                      </div>
                    </div>
                  </div>

                  <!-- Action buttons -->
                  <div
                    class="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6"
                  >
                    <div class="flex justify-end space-x-3">
                      <button
                        type="button"
                        class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        @click="newGuidePanelOpen = false"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

const dashboardGuideStore = useDashboardGuideStore()
const { newGuidePanelOpen } = storeToRefs(dashboardGuideStore)

const geoEntityStore = useGeoEntityStore()
const { addressesTreesWithParentsAndNoGuides } = storeToRefs(geoEntityStore)

const query = ref('')
const selectedAddressTree = ref(null)

const filteredAddressesTrees = computed(() =>
  query.value === ''
    ? addressesTreesWithParentsAndNoGuides.value
    : addressesTreesWithParentsAndNoGuides.value.filter((address) => {
        return address.name.toLowerCase().includes(query.value.toLowerCase())
      })
)

onMounted(async () => {
  if (addressesTreesWithParentsAndNoGuides.value.length === 0) {
    await geoEntityStore.fetchAddressesTrees()
  }
})
</script>

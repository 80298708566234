<template>
  <div class="">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <SvgIcon name="x-mark" class="size-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2"
              >
                <div class="flex h-16 shrink-0 items-center">
                  <img
                    class="h-8 w-auto rounded"
                    src="/logo/quo-logo-512@2x.png"
                    alt="Quo"
                  />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <NuxtLink
                            :to="localePath({ name: item.route })"
                            :class="[
                              isCurrentRoute(item.route)
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                              'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',
                            ]"
                            @click="sidebarOpen = false"
                          >
                            <SvgIcon
                              :name="item.icon"
                              style="solid"
                              :class="[
                                isCurrentRoute(item.route)
                                  ? 'text-indigo-600'
                                  : 'text-gray-400 group-hover:text-indigo-600',
                                'size-6 shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </NuxtLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div class="text-xs/6 font-semibold text-gray-400">
                        Helper tables
                      </div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="table in helper" :key="table.name">
                          <NuxtLink
                            :to="localePath({ name: table.route })"
                            :class="[
                              isCurrentRoute(table.route)
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                              'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',
                            ]"
                            @click="sidebarOpen = false"
                          >
                            <span
                              :class="[
                                isCurrentRoute(table.route)
                                  ? 'border-indigo-600 text-indigo-600'
                                  : 'border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                'flex size-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium',
                              ]"
                              >{{ table.initial }}</span
                            >
                            <span class="truncate">{{ table.name }}</span>
                          </NuxtLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- Desktop-->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col bg-white"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
      >
        <div class="flex h-16 shrink-0 items-center space-x-3">
          <img
            class="h-8 w-auto rounded"
            src="/logo/quo-logo-512@2x.png"
            alt="Quo"
          />
          <div class="font-bold text-[22px] text-gray-800">Quo</div>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink
                    :to="localePath({ name: item.route })"
                    :class="[
                      isCurrentRoute(item.route)
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                  >
                    <SvgIcon
                      :name="item.icon"
                      style="solid"
                      :class="[
                        isCurrentRoute(item.route)
                          ? 'text-indigo-600'
                          : 'text-gray-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li>
              <div class="text-xs font-semibold leading-6 text-gray-400">
                Helper tables
              </div>
              <ul role="list" class="-mx-2 mt-2 space-y-1">
                <li v-for="table in helper" :key="table.name">
                  <NuxtLink
                    :to="localePath({ name: table.route })"
                    :class="[
                      isCurrentRoute(table.route)
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                  >
                    <span
                      :class="[
                        isCurrentRoute(table.route)
                          ? 'text-indigo-600 border-indigo-600'
                          : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                        'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
                      ]"
                      >{{ table.initial }}</span
                    >
                    <span class="truncate">{{ table.name }}</span>
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li class="mt-auto py-4">
              <ClientOnly>
                <ProfileUser
                  class="flex-grow"
                  :profile="profile"
                  :is-quo-pro="isQuoPro"
                  :show-quo-pro-badge="false"
                />
              </ClientOnly>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div
      class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 border-b border-gray-200 sm:px-6 lg:hidden bg-opacity-60 backdrop-blur-sm"
    >
      <button
        type="button"
        class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        @click="sidebarOpen = true"
      >
        <span class="sr-only">Open sidebar</span>
        <SvgIcon name="bars-3" class="size-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm/6 font-semibold text-gray-900">
        {{ currentPageTitle }}
      </div>
      <a href="#">
        <span class="sr-only">Your profile</span>
        <img
          v-if="profielImgSrc"
          class="size-8 rounded-full bg-gray-50"
          :src="profielImgSrc"
          alt=""
        />
      </a>
    </div>
    <slot></slot>
    <DashboardNewGuide v-if="newGuidePanelOpen" />
    <OpenaiChat />
  </div>
</template>
<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
const client = useSupabaseClient()
const profileStore = useProfileStore()
const { isQuoPro, profile } = storeToRefs(profileStore)

const iconStore = useIconStore()
const tagStore = useTagStore()
const dashboardItemStore = useDashboardItemStore()
const geoEntityStore = useGeoEntityStore()

const dashboardGuideStore = useDashboardGuideStore()
const { newGuidePanelOpen } = storeToRefs(dashboardGuideStore)

const route = useRoute()
const localePath = useLocalePath()

const sidebarOpen = ref(false)

const getRouteBaseName = useRouteBaseName()

// const getRouteBaseName = useRouteBaseName()
const baseRouteName = computed(() => {
  return getRouteBaseName(route)
})

const navigation = [
  {
    name: 'Dashboard',
    route: 'dashboard',
    icon: 'home',
  },
  {
    name: 'Places',
    route: 'dashboard-places-id',
    icon: 'map-pin',
  },
  {
    name: 'Guides',
    route: 'dashboard-guides-id',
    icon: 'newspaper',
  },
  {
    name: 'Lists',
    route: 'dashboard-lists-id',
    icon: 'list-bullet',
  },
  {
    name: 'Feed',
    route: 'dashboard-feed-id',
    icon: 'sparkles',
  },
  {
    name: 'Media',
    route: 'dashboard-media-id',
    icon: 'photo',
  },
  {
    name: 'Users',
    route: 'dashboard-users',
    icon: 'user',
  },
]

const helper = [
  {
    id: 1,
    name: 'Countries, States, Cities',
    route: 'dashboard-helper-countries',
    initial: 'c',
  },
  {
    id: 2,
    name: 'Tags',
    route: 'dashboard-helper-tags-id',
    initial: 't',
  },
  {
    id: 3,
    name: 'Icons',
    route: 'dashboard-helper-icons',
    initial: 'i',
    current: false,
  },
]

const isCurrentRoute = (route) => {
  return baseRouteName.value === route
}

const currentPageTitle = computed(() => {
  if (navigation.find((item) => isCurrentRoute(item.route))?.name) {
    return navigation.find((item) => isCurrentRoute(item.route))?.name
  } else if (helper.find((item) => isCurrentRoute(item.route))?.name) {
    return helper.find((item) => isCurrentRoute(item.route))?.name
  }
  return ''
})

await useAsyncData(
  'icons-tags-dashboard-detail-keys-dashboard-geo-entities-geo-entities',
  async () => {
    const [
      icons,
      tags,
      dashboardDetailKeys,
      dashboardGeoEntities,
      geoEntities,
    ] = await Promise.all([
      iconStore.fetchIcons(),
      tagStore.fetchTags(),
      dashboardItemStore.fetchDetailKeys(),
      dashboardItemStore.fetchGeoEntities(),
      geoEntityStore.fetchGeoEntities(),
    ])
    return {
      icons,
      tags,
      detailKeys,
      dashboardDetailKeys,
      dashboardGeoEntities,
      geoEntities,
    }
  }
)

const profielImgSrc = ref('')

const avatarUrl = computed(() => {
  return profile.value.avatarUrl
})

const downloadImage = async () => {
  try {
    if (!avatarUrl.value) return
    const { data, error } = await client.storage
      .from('avatars')
      .download(avatarUrl.value, {
        transform: {
          width: 100,
          height: 100,
          quality: 100,
          resize: 'cover',
        },
      })
    if (error) throw error
    profielImgSrc.value = URL.createObjectURL(data)
  } catch (error) {
    // console.error('Error downloading image: ', error.message)
  }
}

await downloadImage()

watch(
  avatarUrl,
  () => {
    if (!avatarUrl.value) {
      profielImgSrc.value = ''
      return
    }
    downloadImage()
  },
  { immediate: true }
)
</script>
